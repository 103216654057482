import "./connectButton.css";
import Img from "../../assets/images/img.png";
import Close from "../../assets/images/close.png";
import Modal from "react-modal";
import React, { useState, useEffect, memo, useRef } from "react";
import { extractError } from "../../utilities/utils";
import {
  CHAIN_ID,
  CONTRACT_ADDRESS,
  MAXIMUM_NFT_ALLOWED,
  MINIMUM_NFT_ALLOWED,
} from "../../constants/constants";
import Web3 from "web3";
import ABI from "../../abi/abi.json";

function ConnectButton() {
  const [isMintingModalOpen, setIsMintingModalOpen] = useState(false);
  const [isMintingSuccess, setIsMintingSuccess] = useState(false);
  const [nftQuantity, setNftQuantity] = useState(1);
  const [isUserOnCorrectChain, setIsUserOnCorrectChain] = useState(true);
  const web3Ref = useRef( null );
  const [walletAddress, setWalletAddress] = useState("");
  const contractRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [totalSupply, setTotalSupply] = useState(0);
  const [mintedSupply, setMintedSupply] = useState(0);

  const connectMetaMask = async () => {
    web3Ref.current === null && await initializeWeb3() ;
    if (web3Ref.current !== null) {
      const accounts = await web3Ref.current.eth.requestAccounts();
      setWalletAddress(accounts[0]);
      window.ethereum.on("accountsChanged", function (accounts) {
        setWalletAddress(accounts[0]);
      });
    }
  };

  const mintNFT = async (e) => {
    e.target.disabled = true;
    setIsLoading(true);
    const payload = {
      from: walletAddress,
    };
    try {
      await contractRef.current.methods.mintOgre(nftQuantity).call(payload);
      setErrorMessage("");
      const result = await contractRef.current.methods
        .mintOgre(nftQuantity)
        .send(payload);
      console.log(result);
      setIsMintingSuccess(true);
    } catch (error) {
      console.log(error);
      setErrorMessage(extractError(error));
    }
    setIsLoading(false);
    e.target.disabled = false;
    getMintingDetails();
  };

  const getMintingDetails = async () => {
    const totalSupply = await contractRef.current.methods.MAX_SUPPLY().call();
    setTotalSupply(totalSupply);
    const mintedSupply = await contractRef.current.methods
      .tokenCounter()
      .call();
    setMintedSupply(mintedSupply);
  };

  const initializeWeb3 = async () => {
    if (window.ethereum) {
      window.ethereum.on("chainChanged", function (networkId) {
        if (networkId !== CHAIN_ID) {
          setIsMintingModalOpen(true);
          setIsUserOnCorrectChain(false);
        } else {
          setIsUserOnCorrectChain(true);
        }
      });
      if (window.ethereum.chainId !== CHAIN_ID) {
        setIsUserOnCorrectChain(false);
        setIsMintingModalOpen(true);
        return;
      } else {
        web3Ref.current = new Web3(window.ethereum);
        contractRef.current = new web3Ref.current.eth.Contract(
            ABI.abi,
            CONTRACT_ADDRESS
        );

        await getMintingDetails();
        return;
      }
    }

    setTimeout(
      () =>
        window.open(
          "https://metamask.app.link/dapp/window.location/",
          "_blank"
        ),
      1000
    );
  };

  useEffect(() => {
    setIsMintingSuccess(false);
  }, [isMintingModalOpen]);

  const incrementNftQuality = () => {
    nftQuantity < MAXIMUM_NFT_ALLOWED && setNftQuantity((current) => ++current);
  };

  const decrementNftQuality = () => {
    nftQuantity > MINIMUM_NFT_ALLOWED && setNftQuantity((current) => --current);
  };

  const openMintingModal = async () => {
    setIsMintingModalOpen(true);
    await initializeWeb3();
  };

  return (
    <header id="header">
      <button onClick={openMintingModal} className="mint-btn btn-css nova">
        Free Mint
      </button>
      <button
        onClick={connectMetaMask}
        className={`simple-btn btn-css ${walletAddress ? "" : "nova"}`}
      >
        {walletAddress
          ? `${walletAddress.substring(0, 4)}...${walletAddress.substring(
              walletAddress.length - 4
            )}`
          : "CONNECT"}
      </button>
      <div>
        <Modal
          ariaHideApp={false}
          isOpen={isMintingModalOpen}
          onRequestClose={() => {
            setIsMintingModalOpen(false);
            setErrorMessage("");
          }}
          contentLabel="Modal"
          className={`modal-css ${isUserOnCorrectChain ?  isMintingSuccess ? "" : "rectangle" : "min-height"}`}
        >
          <img
            className="topRight"
            onClick={() => {
              setIsMintingModalOpen(false);
              setIsMintingSuccess(false);
              setErrorMessage("");
            }}
            src={Close}
            alt="close"
          />
          <div className="modal-block">

            {isUserOnCorrectChain ? isMintingSuccess ? (
              <div className="centerAlign">
                <img className="gift-img" src={Img} alt="gift" />
                <h2 className="nova">Congratulations</h2>
                <h3>your Mint has been Successful!</h3>
              </div>
            ) : (
              <div className="centerAlign">
                <h4 className={"nova"}>Mint on Ogretown</h4>
                <div className="total">
                  <span>{mintedSupply}</span>
                  <span>/</span>
                  <span>{totalSupply}</span>
                </div>
                <div className="mint-number">
                  <svg onClick={decrementNftQuality} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.36422 21.2272C2.85091 19.2137 1.95352 16.7106 1.95352 14.0003C1.95352 13.4611 1.51594 13.0236 0.976761 13.0236C0.437578 13.0236 0 13.4611 0 14.0003C0 17.1506 1.04324 20.0601 2.80275 22.4015C3.12695 22.8326 3.73915 22.9185 4.17021 22.5956C4.60125 22.2714 4.68846 21.6578 4.36426 21.2268L4.36422 21.2272Z" fill="#030000"/>
                    <path d="M2.50568 10.3863C3.26878 7.95747 4.78209 5.86074 6.77357 4.36446C7.20461 4.04025 7.29051 3.42806 6.96761 2.997C6.64341 2.56596 6.02987 2.47875 5.59886 2.80295C3.28712 4.54147 1.53024 6.9784 0.641998 9.80044C0.480442 10.3148 0.766928 10.8631 1.28147 11.0247C1.79449 11.1875 2.34413 10.9011 2.50571 10.3865L2.50568 10.3863Z" fill="#030000"/>
                    <path d="M10.3861 2.50577C11.527 2.14625 12.7408 1.95351 14.0001 1.95351C20.6488 1.95351 26.0466 7.35163 26.0466 14C26.0466 20.6486 20.6485 26.0465 14.0001 26.0465C11.2898 26.0465 8.78814 25.1504 6.77596 23.6371C6.34491 23.3142 5.73138 23.4014 5.40721 23.8324C5.08431 24.2622 5.17152 24.8757 5.60256 25.1999C7.94288 26.958 10.8511 28 13.9999 28C21.7269 28 28 21.7264 28 14C28 6.27301 21.7264 0 13.9999 0C12.5374 0 11.1257 0.225223 9.79984 0.642092C9.28552 0.803648 8.99878 1.35306 9.16165 1.86762C9.32321 2.38063 9.87134 2.66733 10.3859 2.5058L10.3861 2.50577Z" fill="#030000"/>
                    <path d="M19.2093 13.0236H8.79036C8.25118 13.0236 7.8136 13.4611 7.8136 14.0003C7.8136 14.5395 8.25118 14.9771 8.79036 14.9771H19.2093C19.7485 14.9771 20.1861 14.5395 20.1861 14.0003C20.1861 13.4611 19.7485 13.0236 19.2093 13.0236Z" fill="#030000"/>
                  </svg>
                  <span>0{nftQuantity}</span>
                  <svg onClick={incrementNftQuality} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M23.6358 21.2272C25.1491 19.2137 26.0465 16.7106 26.0465 14.0003C26.0465 13.4611 26.4841 13.0236 27.0232 13.0236C27.5624 13.0236 28 13.4611 28 14.0003C28 17.1506 26.9568 20.0601 25.1973 22.4015C24.873 22.8326 24.2608 22.9185 23.8298 22.5956C23.3987 22.2714 23.3115 21.6578 23.6357 21.2268L23.6358 21.2272Z" fill="#030000"/>
                    <path d="M25.4943 10.3863C24.7312 7.95747 23.2179 5.86074 21.2264 4.36446C20.7954 4.04025 20.7095 3.42806 21.0324 2.997C21.3566 2.56596 21.9701 2.47875 22.4011 2.80295C24.7129 4.54147 26.4698 6.9784 27.358 9.80044C27.5196 10.3148 27.2331 10.8631 26.7185 11.0247C26.2055 11.1875 25.6559 10.9011 25.4943 10.3865L25.4943 10.3863Z" fill="#030000"/>
                    <path d="M17.6139 2.50577C16.473 2.14625 15.2592 1.95351 13.9999 1.95351C7.35125 1.95351 1.95339 7.35163 1.95339 14C1.95339 20.6486 7.35153 26.0465 13.9999 26.0465C16.7102 26.0465 19.2119 25.1504 21.224 23.6371C21.6551 23.3142 22.2686 23.4014 22.5928 23.8324C22.9157 24.2622 22.8285 24.8757 22.3974 25.1999C20.0571 26.958 17.1489 28 14.0001 28C6.27307 28 3.43323e-05 21.7264 3.43323e-05 14C3.43323e-05 6.27301 6.27363 0 14.0001 0C15.4626 0 16.8743 0.225223 18.2002 0.642092C18.7145 0.803648 19.0012 1.35306 18.8383 1.86762C18.6768 2.38063 18.1287 2.66733 17.6141 2.5058L17.6139 2.50577Z" fill="#030000"/>
                    <path d="M13.0232 19.2095V8.79057C13.0232 8.25139 13.4607 7.81381 13.9999 7.81381C14.5391 7.81381 14.9767 8.25139 14.9767 8.79057V19.2095C14.9767 19.7487 14.5391 20.1863 13.9999 20.1863C13.4607 20.1863 13.0232 19.7487 13.0232 19.2095Z" fill="#030000"/>
                    <path d="M8.79067 13.0236H19.2096C19.7488 13.0236 20.1864 13.4611 20.1864 14.0003C20.1864 14.5395 19.7488 14.9771 19.2096 14.9771H8.79067C8.25149 14.9771 7.81391 14.5395 7.81391 14.0003C7.81391 13.4611 8.25149 13.0236 8.79067 13.0236Z" fill="#030000"/>
                  </svg>
                </div>
                {errorMessage && (
                  <span className="error-msg">{errorMessage}</span>
                )}
                <div className="btn-holder">
                  {walletAddress ? (
                    <button
                      onClick={(e) => mintNFT(e)}
                      className={`mint-btn btn-css nova ${
                        isLoading ? "disabled-btn" : ""
                      }`}
                    >
                      Free Mint
                    </button>
                  ) : (
                    <button
                      onClick={connectMetaMask}
                      className="simple-btn btn-css nova"
                    >
                      Connect
                    </button>
                  )}
                </div>
              </div>
            ) :(
              <div className="centerAlign">
                <p className="nova">
                  Incorrect chain - please switch to the Rinkeby network
                </p>
              </div>
            )
            }
          </div>
        </Modal>
      </div>
    </header>
  );
}
export default memo(ConnectButton);
